



























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State as StateClass, Action, Getter } from 'vuex-class';
import { CurrentManager } from '@/models/manager/CurrentManager';
import { Manager, ManagerRole, SpecialUser } from '@/models/manager/Manager';
import { State } from '@/models/State';

@Component
export default class ManagersList extends Vue {
  @StateClass specialUsers!: State['specialUsers'];

  @Getter getCurrentManager!: CurrentManager;
  @Getter getCurrentManagerRole!: ManagerRole;

  @Action setManagerRole!: Function;
  @Action removeManager!: Function;

  columns = ['avatar', 'email', 'role', 'action'];
  options = {
    headings: {
      avatar: '',
      email: 'Manager',
      role: 'Role',
      action: '',
    },
    filterable: ['email'],
    sortable: ['email', 'role'],
    orderBy: {
      column: 'email',
    },
    // columnsClasses strings need to have a space at the end
    // because vue-tables-2-premium adds classes runtime without a space before
    columnsClasses: {
      avatar: 'table__col--avatar table__col--xs ',
      email: 'table__col--email table__col--l ',
      role: 'table__col--role table__col--m ',
      action: 'table__col--action table__col--m table__col--dropdown',
    },
    skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
  };

  // Ids to track manager spacific loading actions
  managerRoleLoading: null | string = null;

  async setRole(manager: Manager, role: string): Promise<void> {
    this.managerRoleLoading = manager.uid;
    await this.setManagerRole({ uid: manager.uid, role });
    this.managerRoleLoading = null;
  }

  isSelf(manager: Manager): boolean {
    return manager.uid === this.getCurrentManager.uid;
  }

  managerIsAdmin(manager: Manager): boolean {
    return manager.role === ManagerRole.Admin;
  }

  managerIsSuperAdmin(manager: Manager): boolean {
    return manager.role === ManagerRole.Superadmin;
  }

  managerIsEditor(manager: Manager): boolean {
    return manager.role === ManagerRole.Editor;
  }

  get allowChangingRoleToAdmin(): boolean {
    return this.getCurrentManagerRole === ManagerRole.Superadmin;
  }

  get allowChangingRoleToEditor(): boolean {
    return this.getCurrentManagerRole === ManagerRole.Superadmin || this.getCurrentManagerRole === ManagerRole.Admin;
  }

  get allowChangingManagerStatus(): boolean {
    return this.getCurrentManagerRole === ManagerRole.Superadmin || this.getCurrentManagerRole === ManagerRole.Admin;
  }

  getRole(specialUser: SpecialUser): string {
    if (specialUser.superadmin) {
      return 'superadmin';
    }

    if (specialUser.admin) {
      return 'admin';
    }

    return 'editor';
  }

  get managers(): { uid: string, email: string, role: string }[] {
    return this.specialUsers.map((specialUser: SpecialUser): { uid: string, email: string, role: string} => ({
      uid: specialUser.id,
      email: specialUser.email,
      role: this.getRole(specialUser),
    }));
  }
}
